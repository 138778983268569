




































































































































































































































































































































































































































import { Component, Vue, Ref, Watch, Prop } from "vue-property-decorator";
import store from "@/store";
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
// external libraries
import { CircleMarkerOptions, latLng, latLngBounds } from "leaflet";
import { LMap, LTileLayer, LControl, LPopup, LTooltip, LIcon, LPolyline, LCircleMarker } from "vue2-leaflet";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import L from "leaflet";
import TimezonesGeoJson from "@/assets/data/TimezonesGeoJson";
import ECAZonesGeo from "@/assets/data/ECAZonesGeo";
// utilities
import performanceStatusHelper from "Utilities/performance-status-helper";
import vesselMapHelper from "Utilities/vessel-map-helper";
import dateHelper from "Utilities/date-helper";
// types
import { MapMarker, MapPrevMarker } from "@/types/mapMarker";
import { VesselStatusIcons } from "@/types/vesselStatusIcons";
import { VesselHistories } from "@/types/vesselHistory";
import { MapSettings } from "@/types/mapSettings";
import { DashboardI } from "@/types/dashboard";
import { VesselGroup } from "@/types/vesselGroup";
import { ExtendedVessel } from "@/types/Vessel";
import { TempPoint } from "@/types/TempPoint";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import MapWidgetModule from "@/store/clients/MapWidget.module";
import VesselGroupsModule from "@/store/clients/VesselGroups.module";
import VesselsHistoriesModule from "@/store/clients/VesselsHistories.module";
import FoulingModule from "@/store/clients/Fouling.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import DashboardModule from "@/store/clients/Dashboard.module";

const Vessels = getModule(VesselsModule, store);
const VesselGroups = getModule(VesselGroupsModule, store);
const VesselsHistories = getModule(VesselsHistoriesModule, store);
const Fouling = getModule(FoulingModule, store);
const MapWidgetData = getModule(MapWidgetModule, store);
const Snackbar = getModule(SnackbarModule, store);
const Dash = getModule(DashboardModule, store);

@Component({
  components: {
    LMap,
    LTileLayer,
    LControl,
    LPopup,
    LTooltip,
    LIcon,
    LPolyline,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
    LCircleMarker,
    RangeSlider,
  },
})
export default class Map extends Vue {
  @Prop({ required: true }) vessels!: ExtendedVessel[];
  @Prop({ default: false }) enableMinimalMode!: boolean;
  @Prop({ default: false }) weatherSeaSurfaceTemperature!: boolean;
  @Prop({ default: false }) isSynchronized!: boolean;
  @Prop({ default: false }) isUsingDerivedStw!: boolean;
  @Prop() resizing!: boolean;
  @Prop() breachLatLng!: { longitude: number; latitude: number }[];
  @Prop() seaTemperatureDateRange!: { from: string; to: string };

  HOUR_IN_MS = 3600000;
  //reference to the marker elements to toggle all popups
  @Ref("markerRef") markerRef!: Vue[];
  //reference to the leaflet map
  @Ref("mapRef") mapRef!: Vue[];
  //store the mapobject from the mapRef here once it is loaded
  map: any = null;
  mapLoading = false;

  //these properties are copied from the knockout version
  maxZoom = 14;
  minZoom = 2;
  url = "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png";
  attribution = "Esri";
  maxBounds = latLngBounds(latLng(-85, -200), latLng(85, 200));
  mapOptions = {
    zoomSnap: 0.5,
    wheelPxPerZoomLevel: 30,
    preferCanvas: false,
  };
  mapSettings: any = {};
  mapSettingsList!: MapSettings[];

  showFilters = false;
  showAllPopups = false;
  showTimezonesGrid = false;
  showECAZonesGrid = false;
  //marker icon size
  iconSize = [12, 29];
  prevIconSize = [15, 15];
  //number of days to show track records (prevMarkers and polyline)
  trackHistoryTimespan = 14;

  popupOptions = {
    autoClose: false,
    closeOnClick: false,
    closeButton: false,
    offset: [0, 10],
    className: "map-widget__custom-leaflet-popup",
  };

  polylineOptions = {
    weight: 4,
    opacity: 0.2,
    smoothFactor: 2.0,
  };

  tooltipOptions = {
    direction: "top",
    offset: [0, -10],
  };

  //all markers
  markers: MapMarker[] = [];
  //min distance between track markers
  minMarkerDistance = 30;
  //set marker rotation origin to be center of icon instead of bottom
  markerRotationOrigin = "center center";
  //any vessels missing lat/lon data
  vesselsMissingPosition: MapMarker[] = [];
  //vessel name filter
  nameFilter = "";
  //holds reference to setInterval to refresh map data
  refreshTimer!: number;
  //How long the notification is shown on screen in ms
  notificationDurationMs = 3500;
  //notification is toggled with this
  showDataRefreshNotification = false;
  showVesselTrack = this.weatherSeaSurfaceTemperature ? false : true;
  leafletLayerGroup: any;
  temperatureHistories: any[] = [];
  gradient = [
    [0, [1, 31, 157]],
    [49, [61, 81, 123]],
    [100, [241, 39, 16]],
  ];

  seaTemperaturePoints: { temperature: number; timestamp: number; latlng: any }[] = [];
  step = 1;

  get performanceStatuses(): { title: string; value: string; icon: VesselStatusIcons }[] {
    return MapWidgetData.performanceStatuses;
  }

  get activeDashboard(): DashboardI {
    return Dash.activeDashboard;
  }

  @Watch("resizing")
  resized(val: boolean): void {
    if (val && this.map) {
      setTimeout(() => {
        this.map.invalidateSize();
        this.mapLoading = false;
      }, 1000);
    }
  }

  //fitBounds zooms and pans map to fit all visible markers
  @Watch("filteredMarkers")
  markersChanged(): void {
    if (!this.weatherSeaSurfaceTemperature) this.fitBounds();
  }

  //toggle show all popups
  @Watch("showAllPopups")
  showAllPopupsChanged(val: boolean): void {
    //  delay to get time for leaflet to load and display markers
    setTimeout(() => {
      if (!this.markerRef.length) return;
      if (val) {
        this.markerRef.forEach(marker => {
          (marker as any).mapObject._popup.options.autoPan = false;
          (marker as any).mapObject.openPopup();
        });
      } else {
        this.markerRef.forEach(marker => {
          (marker as any).mapObject.closePopup();
        });
      }
    }, 500);

    // save map settings
    if (!this.enableMinimalMode) {
      this.mapSettings.vesselNamesAndTracks = val;
    }
  }

  @Watch("showTimezonesGrid")
  showTimezonesGridChanged(val: boolean): void {
    if (val) {
      this.leafletLayerGroup.addLayer(this.timezonesLayer);
    } else {
      this.leafletLayerGroup.removeLayer(this.timezonesLayer);
    }
    // save map settings
    if (!this.enableMinimalMode) {
      this.mapSettings.timezoneGrid = val;
    }
  }

  @Watch("showECAZonesGrid")
  showECAZonesChanged(val: boolean): void {
    if (val) {
      this.leafletLayerGroup.addLayer(this.ECAZonesLayer);
    } else {
      this.leafletLayerGroup.removeLayer(this.ECAZonesLayer);
    }
    // save map settings
    if (!this.enableMinimalMode) {
      this.mapSettings.showECAZonesGrid = val;
    }
  }

  @Watch("mapSettings", { deep: true })
  saveMapSettings(): void {
    const stringMapSettingsList: string | null = localStorage.getItem("mapSettingsList");
    //  if not null or undefined
    if (stringMapSettingsList != null) {
      this.mapSettingsList = JSON.parse(stringMapSettingsList);
      const existingSettingIndex = this.mapSettingsList.findIndex((setting: MapSettings) => setting.dashboardId === this.mapSettings.dashboardId);
      existingSettingIndex !== -1 ? (this.mapSettingsList[existingSettingIndex] = this.mapSettings) : this.mapSettingsList.push(this.mapSettings);
      localStorage.setItem("mapSettingsList", JSON.stringify(this.mapSettingsList));
    } else {
      this.mapSettings = this.defaultMapSettings;
      localStorage.setItem("mapSettingsList", JSON.stringify([this.mapSettings]));
    }
  }

  get vesselGroupItems(): VesselGroup[] {
    const allVessels = {
      id: -1,
      name: "All vessels",
      vesselIds: [],
    };
    const vesselGroupsAndAll = VesselGroups.vesselGroups;
    vesselGroupsAndAll.unshift(allVessels);

    return vesselGroupsAndAll;
  }

  get filteredMarkers(): MapMarker[] {
    let filteredMarkers = this.markers;

    //filter on selected vesselgroup
    if (this.mapSettings.selectedVesselGroup?.length) {
      filteredMarkers = filteredMarkers.filter(marker => {
        return this.mapSettings.selectedVesselGroup.includes(marker.id);
      });
    }

    //filter on vessel name
    if (this.nameFilter.length) {
      filteredMarkers = filteredMarkers.filter(marker => {
        return marker.vessel.name.toLowerCase().includes(this.nameFilter.toLowerCase());
      });
    }

    //filter on performance status
    if (this.mapSettings.selectedPerformanceStatuses?.length) {
      filteredMarkers = filteredMarkers.filter(marker => {
        return this.mapSettings.selectedPerformanceStatuses.includes(marker.performanceStatus);
      });
    }

    return filteredMarkers;
  }

  get timezonesLayer(): any {
    const component = this;
    return L.geoJSON(TimezonesGeoJson as any, {
      style: function (feature) {
        return {
          color: "#c1c1c1",
          opacity: 0.5,
          weight: 2,
          fillColor: component.timezonesGridColor(feature?.properties.zone),
          fillOpacity: 0.2,
        };
      },
    }).bindPopup(function (layer: any) {
      const localDateTime = new Intl.DateTimeFormat("en-GB", {
        timeZone: layer.feature.properties.tz_name1st,
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(new Date());
      return `
                              <b>Timezone:</b> ${layer.feature.properties.time_zone} <br />
                              <b>Local time:</b> ${localDateTime}
                            `;
    });
  }

  get ECAZonesLayer(): any {
    return L.geoJSON(ECAZonesGeo as any, {
      style: {
        color: "#dbfdd1",
        opacity: 0.5,
        weight: 2,
        fillColor: "green",
        fillOpacity: 0.2,
      },
    });
  }

  get timezonesGridColor() {
    return function (zone: number) {
      if (zone === 0) return "#282828";
      return zone % 2 == 0 ? "#FFF" : "#c1c1c1";
    };
  }

  get isFiltersApplied(): boolean {
    return Boolean(this.mapSettings.selectedVesselGroup?.length > 0 || this.mapSettings.selectedPerformanceStatuses?.length < 5);
  }

  get getSeaTemperaturePoints(): TempPoint[] {
    return Fouling.seaSurfaceTemperature;
  }

  tempPointRadius(timeSpentIdling: number): number {
    if (!timeSpentIdling || timeSpentIdling <= 0) return 3;
    if (timeSpentIdling <= 24) return 6;
    return 10;
  }

  get minTemperature(): number | string {
    const temperatures = this.getSeaTemperaturePoints.map(item => item.temperature);
    const min = Number(temperatures.reduce((a, b) => Math.min(a, b), Infinity).toFixed(1));
    return isFinite(min) ? min : "N/A";
  }

  get maxTemperature(): number | string {
    const temperatures = this.getSeaTemperaturePoints.map(item => item.temperature);
    const max = Number(temperatures.reduce((a, b) => Math.max(a, b), -Infinity).toFixed(1));
    return isFinite(max) ? max : "N/A";
  }

  get averageTemperature(): number {
    const temperatures = this.getSeaTemperaturePoints.map(item => item.temperature);
    return Number((temperatures.reduce((a, b) => Math.floor(a) + Math.floor(b), 0) / temperatures.length).toFixed(1));
  }

  get defaultMapSettings(): MapSettings {
    return {
      dashboardId: this.activeDashboard.id,
      selectedPerformanceStatuses: ["Ok", "Observe", "NotOk", "BenchMarking", "notAvailable"],
      selectedVesselGroup: [],
      vesselNamesAndTracks: false,
      timezoneGrid: false,
      showECAZonesGrid: false,
      zoom: this.map.getZoom(),
      center: this.map.getCenter(),
    };
  }

  onMapPopupOpen(marker: MapMarker): void {
    marker.loadingTrackHistory = true;
    this.showTrackRecords(marker);
    if (this.map.getZoom() >= 4) this.refreshPrevMarkers(marker);
  }

  onMapPopupClose(marker: MapMarker): void {
    if (!this.enableMinimalMode) marker.showTrackHistory = false;
    marker.expanded = false;
  }

  onMoveEnd(): void {
    if (this.weatherSeaSurfaceTemperature) return;
    // save map center
    if (!this.enableMinimalMode && this.map.getZoom() > 2.5) {
      this.mapSettings.center = this.map.getCenter();
    }
  }

  onMapZoomEnd(): void {
    if (this.weatherSeaSurfaceTemperature) return;
    // save map zoom level
    if (!this.enableMinimalMode && this.map.getZoom() > 2.5) {
      this.mapSettings.zoom = this.map.getZoom();
    }

    //  Distance between prevMarkers change on zoom, so we need to refresh them.
    //  wrapped in settimeout to add it to js tasks queue which improve map update
    setTimeout(() => {
      //  get markers ids that is currently visible in map viewport
      const mainMarkersInViewIdList = this.getMainMarkersInViewIdList();
      // Show prev markers only when zoom equal or higher than 4
      if (this.map.getZoom() >= 4) {
        this.markers.forEach(marker => {
          if (mainMarkersInViewIdList.includes(marker.id) && marker.trackHistory.loaded) {
            this.refreshPrevMarkers(marker);
          }
        });
        //  Otherwise show just vessel history track line
      } else {
        this.markers.forEach(marker => {
          marker.trackHistory.prevMarkers = [];
        });
      }
    });
  }

  getMainMarkersInViewIdList(): number[] {
    const mainMarkersInViewIdList: number[] = [];
    const map = this.map;
    map.eachLayer(function (layer: any) {
      const isLayerPolyline: boolean = layer instanceof L.Polyline;
      const isPolylineInView: boolean = isLayerPolyline ? map.getBounds().intersects(layer.getBounds()) : false;
      const polylineMainMarkerId = (layer.options as any).markerId;

      if (isLayerPolyline && isPolylineInView && polylineMainMarkerId) {
        mainMarkersInViewIdList.push((layer.options as any).markerId);
      }
    });
    return mainMarkersInViewIdList;
  }

  closePopup(vesselId: number): void {
    this.markerRef.forEach(marker => {
      if (marker.$vnode.key === vesselId) {
        (marker as any).mapObject.closePopup();
      }
    });
  }

  toggleExtendedPopup(marker: MapMarker): void {
    marker.expanded = !marker.expanded;
    const markerRef: any = this.markerRef.find(ref => ref.$vnode.key === marker.id);

    if (marker.expanded) {
      //Waiting for the resize to finish before adjusting pan to popup
      setTimeout(function () {
        markerRef?.mapObject.getPopup()._adjustPan();
      }, 100);
    }
  }

  temperatureColor(teperatureAsPercentage: any): string {
    var colorRange: any = [];
    const percent = 100;

    if (teperatureAsPercentage <= 0) return "rgb(81, 81, 81)";

    this.gradient.forEach((item, index) => {
      if (teperatureAsPercentage <= item[0]) {
        colorRange = [index - 1, index];
        return false;
      }
    });

    if (!colorRange.length) colorRange = [0, 1];
    // Get the two closest colors
    var firstcolor = this.gradient[colorRange[0]][1];
    var secondcolor = this.gradient[colorRange[1]][1];

    // Calculate ratio between the two closest colors
    var firstcolor_x = percent * ((this.gradient[colorRange[0]][0] as any) / 100);
    var secondcolor_x = percent * ((this.gradient[colorRange[1]][0] as any) / 100) - firstcolor_x;
    var scale_x = percent * (teperatureAsPercentage / 100) - firstcolor_x;
    var ratio = scale_x / secondcolor_x;

    return `rgb(${this.pickHex(secondcolor, firstcolor, ratio).join()})`;
  }

  pickHex(color1: any, color2: any, weight: number): number[] {
    var p = weight;
    var w = p * 2 - 1;
    var w1 = (w / 1 + 1) / 2;
    var w2 = 1 - w1;
    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2), Math.round(color1[1] * w1 + color2[1] * w2), Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb;
  }

  async showTrackRecords(marker: MapMarker): Promise<void> {
    if (!marker) return;
    marker.showTrackHistory = true;

    if (!marker.trackHistory.loaded) {
      const data = { vessel: marker.vessel, timespan: this.trackHistoryTimespan };
      const vesselHistories: VesselHistories = await VesselsHistories.getVesselHistories(data);

      this.loadPolyline(marker, vesselHistories);
      marker.trackHistory.loaded = true;
    }

    marker.loadingTrackHistory = false;
  }

  async showSeaTemperaturePoints(): Promise<void> {
    this.mapLoading = true;

    try {
      await this.fetchWeatherData();
      this.addTempPoints();
      setTimeout(() => {
        this.map.invalidateSize();
      }, 1000);
    } finally {
      this.mapLoading = false;
    }
  }

  percentageFromTemperature(temperature: number): number {
    return ((temperature - Number(this.minTemperature)) / (Number(this.maxTemperature) - Number(this.minTemperature))) * 100;
  }

  async refreshPrevMarkers(marker: MapMarker): Promise<void> {
    const data = { vessel: marker.vessel, timespan: this.trackHistoryTimespan };
    const vesselHistories: VesselHistories = await VesselsHistories.getVesselHistories(data);

    this.loadPrevMarkers(marker, vesselHistories);
  }

  loadPrevMarkers(marker: MapMarker, vesselHistories: VesselHistories): void {
    const vessel = marker.vessel;
    let prevLatLng = latLng(vessel.latitude, vessel.longitude);
    marker.trackHistory.prevMarkers = [];
    vesselHistories.vesselHistories.forEach((vesselHistory, index) => {
      if (vesselHistory.latitude && vesselHistory.longitude) {
        const prevLayerPoint = (this.mapRef as any).mapObject.latLngToLayerPoint(prevLatLng);
        const prevMarker: MapPrevMarker = {
          position: latLng(vesselHistory.latitude, vesselHistory.longitude),
          heading: Math.round(vesselHistory.course),
          icon: VesselStatusIcons["PreviousPosition"],
          vesselHistory: vesselHistory,
          lastExportDate: dateHelper.getFormatedDateTimeString(vesselHistory.exportDate),
        };

        const newLayerPoint = (this.mapRef as any).mapObject.latLngToLayerPoint(prevMarker.position);

        if (newLayerPoint.distanceTo(prevLayerPoint) >= this.minMarkerDistance) {
          marker.trackHistory.prevMarkers.push(prevMarker);
          prevLatLng = latLng(vesselHistory.latitude, vesselHistory.longitude);
        }
      }
    });
  }

  loadPolyline(marker: MapMarker, vesselHistories: VesselHistories): void {
    const polyline = vesselMapHelper.createPolylinesForMapWidget(vesselHistories.vesselHistories);
    marker.trackHistory.polyline = polyline;
  }

  //fit bounds to markers
  fitBounds(): void {
    if (this.filteredMarkers.length) {
      this.map.fitBounds(
        this.filteredMarkers.map(m => {
          return [m.position.lat, m.position.lng];
        }),
        { maxZoom: this.enableMinimalMode ? 2 : 7 }
      );
    }
  }

  navigateToVesselMarker(): void {
    if (this.filteredMarkers.length) {
      const vesselPosition = this.filteredMarkers.find(m => m.vessel)?.position;
      if (vesselPosition) {
        this.map?.setView(latLng(vesselPosition.lat, vesselPosition.lng));
      }
    }
  }

  mapLoaded(): void {
    const ref: any = this.mapRef;
    this.map = ref.mapObject;
    this.leafletLayerGroup = L.layerGroup();
    this.leafletLayerGroup.addTo(this.map);
    this.map.getRenderer(this.map).options.padding = 10;

    //allow time for widget to get placed and sized on dashboard before refreshing map
    //not sure if there is a better way to do this
    setTimeout(() => {
      if (!this.enableMinimalMode) {
        this.setMapStoredSettings();
        this.showAllPopups = this.mapSettings.vesselNamesAndTracks;
        this.showTimezonesGrid = this.mapSettings.timezoneGrid;
        this.showECAZonesGrid = this.mapSettings.showECAZonesGrid;
        this.map.invalidateSize();
      }
    }, 500);
  }

  setMapStoredSettings(): void {
    const stringMapSettingsList: string | null = localStorage.getItem("mapSettingsList");
    //  if not undefined or null
    if (stringMapSettingsList != null) {
      this.mapSettingsList = JSON.parse(stringMapSettingsList);
      const existingSettingIndex = this.mapSettingsList.findIndex((setting: MapSettings) => setting.dashboardId === this.activeDashboard.id);
      existingSettingIndex !== -1 ? (this.mapSettings = this.mapSettingsList[existingSettingIndex]) : (this.mapSettings = this.defaultMapSettings);
      //  delay to rewrite changes on a map object and then set saved center and zoom
      setTimeout(() => {
        this.map.setView([this.mapSettings?.center?.lat, this.mapSettings?.center?.lng], this.mapSettings?.zoom);
      }, 500);
    } else {
      this.mapSettings = this.defaultMapSettings;
    }
  }

  prettyLongLatValue(value: number, variable: string): string | undefined {
    if (variable === "latitude") return this.$root.$options.filters?.prettyLatitude(value);
    else if (variable === "longitude") return this.$root.$options.filters?.prettyLongitude(value);
  }

  tempPointTooltipHtml(tempPoint: TempPoint): string {
    return `
                            <div class="vessel-popup">
                                <div class="vessel-popup__content">
                                  <table class="vessel-popup__more-info">
                                    <tr>
                                      <td>Temperature</td>
                                      <td>
                                        <span
                                          class="tooltip-temp"
                                          style="background-color: ${this.temperatureColor(this.percentageFromTemperature(tempPoint.temperature))}"
                                        >${Number(tempPoint.temperature.toFixed(1)) > 0 ? `${tempPoint.temperature.toFixed(1)} &deg;` : "N/A"}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Timestamp</td>
                                      <td>${this.convertTimestamp(tempPoint.timestamp)}</td>
                                    </tr>
                                    <tr>
                                      <td>Latitude</td>
                                      <td>${this.prettyLongLatValue(tempPoint.latlng[1], "latitude")}</td>
                                    </tr>
                                    <tr>
                                      <td>Longitude</td>
                                      <td>${this.prettyLongLatValue(tempPoint.latlng[1], "longitude")}</td>
                                    </tr>
                                    ${
                                      !tempPoint.timeSpent
                                        ? `<tr>
                                      <td>${this.isUsingDerivedStw ? "Derived STW" : "Speed Log"}</td>
                                      <td>${tempPoint.shipSpeed ? `${tempPoint.shipSpeed.toFixed(1)} knot` : "N/A"}</td>
                                    </tr>`
                                        : ""
                                    }

                                    ${
                                      tempPoint.timeSpent
                                        ? `<tr>
                                      <td>Time Spent Idling</td>
                                      <td>${Math.round(tempPoint.timeSpent)} hours</td>
                                    </tr>`
                                        : ""
                                    }
                                  </table>
                                </div>
                            </div>
                          `;
  }

  //generate markers
  createMarkers(): void {
    this.markers = [];
    this.vesselsMissingPosition = [];
    this.vessels.forEach(vessel => {
      //returns a string that can be either as valid key of the VesselStatusIcon enum, or something else
      let performanceStatus: any = performanceStatusHelper.getPerformanceStatus(vessel);
      let iconUrl = VesselStatusIcons[performanceStatus] as unknown as VesselStatusIcons;
      //when it is not a valid key, set it to 'notAvailable'
      if (!iconUrl) {
        performanceStatus = "notAvailable";
        iconUrl = VesselStatusIcons["notAvailable"];
      }

      const marker: MapMarker = {
        id: vessel.id,
        position: latLng(vessel.latitude, vessel.longitude),
        heading: Math.round(vessel.course),
        icon: iconUrl,
        vessel: vessel,
        performanceStatus: performanceStatus,
        expanded: false,
        lastExportDate: dateHelper.getFormatedDateTimeString(vessel.lastExportDate),
        trackHistory: {
          loaded: false,
          polyline: [],
          prevMarkers: [],
        },
        showTrackHistory: false,
        loadingTrackHistory: false,
      };
      //only add vessels with both lat and long data
      //should we list vessels missing positional data in some way?
      if (vessel.latitude && vessel.longitude) {
        this.markers.push(marker);
      } else {
        this.vesselsMissingPosition.push(marker);
      }
    });
    //  display vessel track records if map is in vessel info mode
    if (this.enableMinimalMode) this.showTrackRecords(this.markers[0]);
  }

  //refresh data and show notification
  async refreshData(): Promise<void> {
    if (Vessels.extendedVesselsExpired) {
      await Vessels.refreshExtendedVessels();
    }
    this.createMarkers();
    this.showDataRefreshNotification = true;
  }

  onSelectedPerformanceStatusesChange(): void {
    if (this.mapSettings.vesselNamesAndTracks) {
      //  Needs to be in an event loop queue to trigger after the map rewrite markers
      setTimeout(() => this.showAllPopupsChanged(true));
    }
  }

  onSelectedVesselGroupChange(): void {
    if (this.mapSettings.vesselNamesAndTracks) {
      //  Needs to be in an event loop queue to trigger after the map rewrite markers
      setTimeout(() => this.showAllPopupsChanged(true));
    }
  }

  onNameFilterChange(val: string): void {
    if (this.mapSettings.vesselNamesAndTracks && val === "") {
      //  Needs to be in an event loop queue to trigger after the map rewrite markers
      setTimeout(() => this.showAllPopupsChanged(true));
    }
  }

  async refreshVesselGroups(): Promise<void> {
    try {
      await VesselGroups.refreshVesselGroups();
    } catch (error) {
      console.warn("An error occured while loading map widget", error);
      Snackbar.showSnackbar({ text: "An error occurred while loading map widget." });
    }
  }

  showBreachIncident(): void {
    if (!this.breachLatLng) return;
    this.mapLoading = true;
    const polyline = vesselMapHelper.createPolylinesForMapWidget(this.breachLatLng);
    L.polyline(polyline, {
      color: "red",
      weight: 4,
      opacity: 1,
    }).addTo(this.map);
    setTimeout(async () => {
      this.map.invalidateSize();
      await this.map.fitBounds(polyline, 5);
      this.mapLoading = false;
    }, 1000);
  }

  addTempPoints(): void {
    this.map.eachLayer((layer: any) => {
      const isLayerMarker: boolean = layer instanceof L.CircleMarker;
      if (isLayerMarker) this.map.removeLayer(layer);
    });
    const length = this.getSeaTemperaturePoints.length;
    for (let i = 0; i < length; i++) {
      var marker = L.circleMarker({ lat: this.getSeaTemperaturePoints[i].latlng[0], lng: this.getSeaTemperaturePoints[i].latlng[1] }, {
        color: this.temperatureColor(this.percentageFromTemperature(this.getSeaTemperaturePoints[i].temperature)),
        fillColor: this.temperatureColor(this.percentageFromTemperature(this.getSeaTemperaturePoints[i].temperature)),
        radius: this.tempPointRadius(this.getSeaTemperaturePoints[i].timeSpent),
        timestamp: this.getSeaTemperaturePoints[i].timestamp,
        boostType: "circle",
        boostScale: 1,
        boostExp: 0,
      } as Partial<CircleMarkerOptions>);
      marker.on("mouseover", e => {
        var coord = L.latLng(this.getSeaTemperaturePoints[i].latlng[0], this.getSeaTemperaturePoints[i].latlng[1]);
        L.tooltip().setLatLng(coord).setContent(this.tempPointTooltipHtml(this.getSeaTemperaturePoints[i])).addTo(this.map);
      });
      marker.on("mouseout", e => {
        this.map.eachLayer((layer: any) => {
          const isLayerTooltip: boolean = layer instanceof L.Tooltip;
          if (isLayerTooltip) this.map.removeLayer(layer);
        });
      });
      marker.addTo(this.map);
    }
  }

  async onTrackClick(marker: MapMarker): Promise<void> {
    await this.refreshPrevMarkers(marker);
    this.$emit("onTrackClicked", marker);
  }

  onMarkerClick(marker: MapPrevMarker): void {
    this.$emit("onMarkerClicked", marker);
  }

  async fetchWeatherData(): Promise<void> {
    try {
      await Fouling.fetchSeaSurfaceTemperature({ shipId: this.vessels[0].id, fromDate: this.seaTemperatureDateRange?.from ?? null, toDate: this.seaTemperatureDateRange?.to ?? null });
    } catch (err) {
      Snackbar.showSnackbar({ text: "Failed to load sea surface temperature history" });
    }
  }

  async updateWeatherData(range: { from: string; to: string }): Promise<void> {
    this.mapLoading = true;
    try {
      Fouling.CLEAR_SEA_SURFACE_TEMPERATURE();
      await Fouling.updateSeaSurfaceTemperature({ shipId: this.vessels[0].id, fromDate: this.seaTemperatureDateRange?.from ?? null, toDate: this.seaTemperatureDateRange?.to ?? null });
      this.addTempPoints();
      setTimeout(() => {
        this.map.invalidateSize();
        this.navigateToVesselMarker();
      }, 1000);
    } finally {
      this.mapLoading = false;
    }
  }

  convertTimestamp(timestamp: number): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  async created(): Promise<void> {
    if (!this.weatherSeaSurfaceTemperature) await this.refreshVesselGroups();
    this.refreshData();
    this.startRefreshTimer();
    if (this.breachLatLng) this.showBreachIncident();
    if (this.weatherSeaSurfaceTemperature) {
      await this.showSeaTemperaturePoints();
      this.navigateToVesselMarker();
    }
  }

  //map data is refreshed once per hour
  startRefreshTimer(): void {
    this.refreshTimer = setInterval(() => {
      this.refreshData();
    }, this.HOUR_IN_MS);
  }

  onShowVesselTrackChange(showVesselTrack: boolean): void {
    if (showVesselTrack) this.showTrackRecords(this.markers[0]);
    else this.markers[0].showTrackHistory = false;
  }

  beforeDestroy(): void {
    clearInterval(this.refreshTimer);
  }
}
